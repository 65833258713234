import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import PageLayout from "../layouts/PageLayout/PageLayout";
import ScrollingNavLayout from "../layouts/ScrollingNavLayout/ScrollingNavLayout";
import {
  CommonContext,
  SanityCampaign,
  SanityPage,
  SanityRawContent
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query GenericPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityGenericPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      metaDescription
      slug {
        current
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "otherPages" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const GenericPage = ({
  pageContext,
  data: { menus, page, campaigns },
  location
}: GenericPageProps) => {
  const { title, _rawContent } = page;
  return (
    <PageLayout
      // Perhaps we should set this from the CMS?
      siteArea={SiteArea.UNKNOWN}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
      isErrorPage={page.slug?.current === "404"}
    >
      <ScrollingNavLayout
        campaigns={campaigns.nodes}
        title={title}
        content={_rawContent}
        social={false}
      />
    </PageLayout>
  );
};

export default GenericPage;

interface GenericPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityPage & {
      _rawContent: SanityRawContent;
      slug: { current: string };
    };
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
